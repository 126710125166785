/**
 * Bulma Reference
 * http://bulma.io/documentation/overview/variables/
 */
@import '~bulma/sass/utilities/initial-variables';

/**
 * Global Font
 */
$family-primary: 'Spartan';

body {
  height: 100%;
  min-height: 100vh;
}

@import '../themes/_all';

/**
 * Base Bulma Styles
 */
@import '~bulma/bulma.sass';
