.dark {
  /**
   * Body Settings
   */
  background: #000;
  @include background-transition;

  p {
    color: #ccc;

    strong {
      color: #0a72af;
    }
  }

  pre {
    code {
      background: #0a72af;
      color: #000000;
    }
  }

  .hero-head {
    .navbar {
      .navbar-menu {
        &.is-active {
          background: #000000;
        }

        .navbar-start {
          a {
            color: #ffffff;

            &:hover {
              color: #8d8d8d;
            }
          }
        }
      }
    }
  }
}
