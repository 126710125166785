@mixin background-transition($value: 200ms) {
  -webkit-transition: background $value linear;
  -ms-transition: background $value linear;
  transition: background $value linear;
}

@mixin border-transition($value: 0.2s) {
  -webkit-transition: border $value ease-in-out;
  -moz-transition: border $value ease-in-out;
  -o-transition: border $value ease-in-out;
  transition: border $value ease-in-out;
}

@mixin color-transition($value: 0.2s) {
  -webkit-transition: color $value ease-in-out;
  -moz-transition: color $value ease-in-out;
  -o-transition: color $value ease-in-out;
  transition: color $value ease-in-out;
}
