.light {
  /**
   * Body Settings
   */
  background: #fff;
  @include background-transition;

  p {
    color: #000;
  }

  pre {
    code {
      color: #0a72af;
    }
  }

  .hero-head {
    .navbar {
      .navbar-menu {
        &.is-active {
          background: #ffffff;
        }

        .navbar-start {
          a {
            color: #6d6d6d;

            &:hover {
              color: #000000;
            }
          }
        }
      }
    }
  }
}
